import Icons from "@/assets/Icons";
import { useFlowContext } from "@/context/FlowContext";
import { getQueryVariable } from "@/utils";
import { ProgressBar } from "@ravenpay/raven-bank-ui";
import { useEffect, useState } from "react";
import "~/steps/Step.Six.scss";

interface Props {
  onComplete?(): void;
  isLoading?: boolean;
}

const UploadingIDStep = ({ onComplete, isLoading }: Props) => {
  const {  flowState } = useFlowContext();
  const [sec, setSec] = useState(Math.random());
  const mode = getQueryVariable("origin");

  useEffect(() => {
    const interval = setInterval(() => {
      if (sec <= 8) {
        /** Increment Progress only if it is loading */
        if (isLoading) {
          setSec(sec + 1.5 + Math.random());
        }
      }

      /** Goto next step only if it is done loading */
      if (!isLoading && onComplete) {
        clearInterval(interval);
        onComplete();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sec, isLoading]);

  return (
    <div className="vst-six space-y-40">
      <div className="verification-header">
        <h4  className="verification-title">Your identity is being verified</h4>
        <p>Please wait while we verify your identity , this might take a few mins</p>
      </div>
      <figure className="vst-six__uploading">
        <div>{mode === "redpay" ? Icons.IDCardXlRedpay :  Icons.IDCardXl}</div>
        <span className="verification-title">Uploading Details</span>
      </figure>
      <div className="vst-six__status">
        <ProgressBar
          bgcolor={mode === "redpay" ? "rgba(191, 0, 16, 1)"  : flowState?.color || "#755AE2"}
          completed={sec * 10}
        />
        <p>Verifying...</p>
      </div>
    </div>
  );
};

export default UploadingIDStep;
