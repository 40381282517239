import React from "react";

interface MyComponentProps {
  title?: string;
  text?: string;
}

const TitleTextComponent = ({ text, title }: MyComponentProps) => {
  return (
    <div className="title-text-comp-reusable-box">
      <p className="title">{title}</p>
      <div className="text">
        {text?.split("\n")?.map((_, idx) => {
          return <p key={idx}>{_}</p>;
        })}
      </div>
    </div>
  );
};

export default TitleTextComponent;
