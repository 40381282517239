// export function postMessage(type: string, message: any) {
//   window.parent.postMessage({ type: type, message: message }, "*");
// }

export function postMessage(type: string, message: any) {
  const formattedMessage = { type, message };
  // console.log(formattedMessage);
  if (window.ReactNativeWebView) {
    // ✅ Sending message to Flutter WebView
    window.ReactNativeWebView.postMessage(JSON.stringify({ type, formattedMessage }));
  } else if (window.FlutterChannel) {
    // ✅ Sending message via Flutter JavaScript Channel
    window.FlutterChannel.postMessage(JSON.stringify({ type, formattedMessage }));
  } else if (window.flutter_inappwebview?.postMessage) {
    window.flutter_inappwebview.callHandler(
      "receiveMessage",
      JSON.stringify({ type: "success_data", message: formattedMessage })
    );
  } else {
    // Default postMessage for other cases
    window.parent.postMessage(JSON.stringify({ type, formattedMessage }), "*");
  }
}
