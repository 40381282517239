import "~/steps/Step.One.scss";
// import InfoSection from "../reuseables/InfoSection";
import Button from "../fragments/Button";
import { useIDContext } from "@/context/IDContext";
// import { IDCopy } from "@/constants";
import { InfoBoxProps } from "@/component/fragments/InfoBox";
import NewFittedLayout from "./new-screen/NewFittedLayout";
import TitleTextComponent from "./new-screen/TitleTextComponent";

interface Props {
  heroImg: string;
  title: string;
  text?: string;
  onNext?(): void;
  infoCopy: InfoBoxProps[];
}

const LandingStep = (props: Props) => {
  const { title, onNext, text } = props;

  const { dispatch } = useIDContext();

  const onClick = () => {
    if (onNext) {
      onNext();
    } else {
      dispatch({ type: "advance" });
    }
  };

  return (
    <NewFittedLayout
      textDesCOntent={
        <>
          <TitleTextComponent title={title} text={text} />
        </>
      }
      btnComponent={<Button onClick={onClick}>Get Started</Button>}
    />
  );

  // return (
  //   <div className="verification__step--one">
  // <div className="hero-banner" style={{ backgroundImage: `url(${heroImg})` }}></div>
  // <h1 className="verification-title">{title}</h1>
  // <InfoSection infoData={IDCopy} />
  //     <div className="btn-wrap--x">
  //       <Button onClick={onClick}>Get Started</Button>
  //     </div>
  //   </div>
  // );
};

export default LandingStep;
