import LandingStep from "@/component/steps/LandingStep";
import SwapToPhone from "@/component/steps/SwapToPhone";
import { BVNCopy, CreateStepErrorMsg } from "@/constants";
import { RavenModal } from "@ravenpay/raven-bank-ui";
import HeroImg from "@/assets/BVNHeaderImage.png";
import useStateRefresh from "@/hooks/useStateRefresh";
import { useBVNContext } from "@/context/BVNContext";
import EnterBVNStep from "@/component/steps/EnterVerificationNumStep";
import TipsForVideo from "@/component/steps/TipsForVideo";
import LivenessCheck from "@/component/steps/LivenessCheck";
import ConfirmImageCapture from "@/component/steps/ConfirmImageCapture";
import VerificationCompleted from "@/component/steps/VerificationCompleted";
import { cn } from "@/utils";
// import { DesktopBackButton } from "@/component/fragments/BackButton";
import { useDisplayContext } from "@/context/DisplayContext";
import { useFlowContext } from "@/context/FlowContext";

const BVNDesktop = () => {
  const { dispatch, state } = useBVNContext();

  const { dispatch: displayDispatch, displayState } = useDisplayContext();

  const { showModal } = displayState;

  const { flowState } = useFlowContext();

  const ninMode = Boolean(flowState.ninOrBVN);

  const contentLoaded = useStateRefresh({
    effect: () => dispatch({ type: "advance", step: 1 }),
  });

  const { step } = state;

  const errorMsg = CreateStepErrorMsg(step);

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <LandingStep
            heroImg={HeroImg}
            title="BVN Verification "
            infoCopy={BVNCopy}
            text={"Let’s get you started with your \n BVN Verification"}
            onNext={() => dispatch({ type: "advance" })}
          />
        );

      case 2:
        return (
          <EnterBVNStep
            title={!ninMode ? "Enter your BVN" : "Confirm your BVN"}
            desc="We need your BVN so you can get verified on Raven"
            hintText={
              <p>
                Dial <span className="accent">*565*0#</span> to get your BVN Number, must be with
                the registered phone number.
              </p>
            }
            inputLabel={!ninMode ? "Enter BVN" : "BVN"}
            onAdvance={(bvn) => {
              if (state.file) {
                dispatch({ type: "*", payload: { error: undefined, bvn, step: 3 } });
              } else {
                dispatch({ type: "addBVN", payload: bvn });
              }
            }}
            inputValue={state.bvn}
            error={state.error}
          />
        );
      case 3:
        return (
          <TipsForVideo
            onNext={() => dispatch({ type: "advance" })}
            onVerifyWithPhone={() => dispatch({ step: 100, type: "advance" })}
          />
        );
      case 4:
        return (
          <LivenessCheck
            type="bvn"
            value={state.bvn}
            onCapture={(src) => dispatch({ type: "addImage", payload: src })}
            onBack={() => dispatch({ type: "previous" })}
          />
        );
      case 5:
        return (
          <ConfirmImageCapture
            state={state}
            onComplete={() => dispatch({ type: "advance" })}
            onRetakeSelfie={() => dispatch({ type: "previous" })}
            type="bvn"
            onFetchError={(err) =>
              dispatch({ type: "onFetchError", payload: { error: err, redirectTo: 2 } })
            }
          />
        );
      case 6:
        return (
          <VerificationCompleted
            className="text-center"
            onClick={() => displayDispatch({ type: "closeModal" })}
            type="BVN"
          />
        );
      case 100:
        return <SwapToPhone />;
      default:
        throw new Error(errorMsg);
    }
  };

  return (
    <div className={cn("v-step v-step__modal", step === 6 && "v-bvn-success")}>
      {contentLoaded && (
        <RavenModal
          onClose={() =>
            step < 6
              ? displayDispatch({ type: "onCloseClick" })
              : displayDispatch({ type: "closeModal" })
          }
          visble={showModal}
          closeColor
          className="verification-modal"
          onBtnClick={() => {}}
          outerClose
        >
          {/* {step > 1 && step < 6 && (
            <DesktopBackButton onClick={() => dispatch({ type: "previous" })} />
          )} */}
          <div style={{ marginTop: "0.3rem" }} className="verification__content">
            {renderSteps()}
          </div>
        </RavenModal>
      )}
    </div>
  );
};

export default BVNDesktop;
