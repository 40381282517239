import { RavenToast, toast } from "@ravenpay/raven-bank-ui";
import { IDProvider } from "./context/IDContext";
import BorderFrame from "@/assets/BorderFrames.png";
import HeaderImage from "@/assets/HeaderImage.png";
import Success from "@/assets/VST-Success.png";
import HideContent from "./component/fragments/HideContent";
import { BVNContextProvider } from "./context/BVNContext";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { AllRoutes } from "./routes";
import useGetReference from "./hooks/useGetReference";
import { useEffect } from "react";
import { cn, getQueryVariable,  sendIframeMessage } from "./utils";
import { useFlowContext } from "./context/FlowContext";
import { useDisplayContext } from "./context/DisplayContext";
import { CloseAlertModal, ErrorModal } from "./component/reuseables/Modal";
import { postMessage } from "./utils/helper";

const images = [BorderFrame, HeaderImage, Success];

const App = () => {
  const [query] = useSearchParams();

  const { setFlowState, flowState } = useFlowContext();
  const { assertDisplay, dispatch, displayState } = useDisplayContext();

  const loading = useGetReference({
    onError: (error) => dispatch({ type: "onError", payload: error }),
  });

  // log(flowState, loading);

  const CoalesceColor = {
    "black-dark": "dark-dark",
    "deep-green": "deep-green-light",
    "info-right": "info-light",
  };

  type CoalesceColorKeys = keyof typeof CoalesceColor;

  useEffect(() => {
    const style = JSON.parse(query.get("style") ?? "{}");

    if ("color" in style) {
      style.color = CoalesceColor[style.color as CoalesceColorKeys] ?? style.color;
    }

    setFlowState((old) => ({ ...old, ...style }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  /**
   * Listen for parent window to post non style related  config
   */
  useEffect(() => {
    const handleAppInit = (e: MessageEvent) => {
      if (e.data.scope === "verification") {
        const { config } = e.data;
        setFlowState((old) => ({
          ...old,
          mobileCallBack: config.mobileCallBackURL,
          successImage: config?.successImage,
        }));

        sendIframeMessage({
          data: { type: "appInitialized" },
          type: "onLoad",
        });
      }
    };

    window.addEventListener("message", handleAppInit);

    return () => {
      window.removeEventListener("message", handleAppInit);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mode = getQueryVariable("origin");

    if (mode !== "business" && mode !== "bankbox" && mode !== "redpay") {
      setFlowState((old) => ({
        ...old,
        color: "purple-light",
        accentColor: "rgba(117, 90, 226, 1)",
        iconColor: "rgba(117, 90, 226, 1)",
        iconBoxBg: "rgba(242, 232, 255, 1)",
      }));
    } else {
      if (mode === "bankbox") {
        setFlowState((old) => ({
          ...old,
          color: "deep-green-light",
          accentColor: "#020202",
          iconColor: "#0b8376",
          iconBoxBg: " rgba(1, 67, 69, 0.05)",
        }));
      }
      if (mode === "redpay") {
        setFlowState((old) => ({
          ...old,
          color: "black-light",
          accentColor: "rgba(236, 19, 57, 1)",
          iconColor: "rgba(236, 19, 57, 1)",
          iconBoxBg: "rgba(253, 236, 239, 1)",
        }));
      }
      if (mode === "business") {
        setFlowState((old) => ({
          ...old,
          color: "black-light",
          accentColor: "rgba(27, 27, 27, 1)",
          iconColor: "rgba(27, 27, 27, 1)",
          iconBoxBg: "rgba(27, 27, 27, 0.05)",
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IDProvider>
      <BVNContextProvider>
        <RavenToast />

        <main
          style={
            {
              "--outline-color": flowState.accentColor ?? "#755ae2",
              "--icon-box-bg": flowState.iconBoxBg ?? "#f0eeff",
              "--icon-color": flowState.iconColor ?? "#755ae2",
            } as React.CSSProperties
          }
          className={cn(assertDisplay("error") && "hide-app-content")}
        >
          {loading && (
            <div className="loader-spinner">
              <div className="xyz-loader" />
            </div>
          )}
          <div className={cn(loading && "loading-content")}>
            {assertDisplay("close") && (
              <CloseAlertModal
                onClose={() => dispatch({ type: "closeModal" })}
                onContinue={() => {
                  const obj = {
                    info: "This is for ending the session",
                    status: "End session",
                  };
                  postMessage("close_session", obj);
                  dispatch({ type: "onContinue" });
                }}
              />
            )}
            {assertDisplay("error") && (
              <ErrorModal
                error={displayState.errorMessage}
                onClose={() => {
                  toast.info("A message has been sent to our support team");
                  dispatch({ type: "closeErrorModal" });
                }}
              />
            )}

            {/* Always show modals, but hide them with opacity of 0 */}
            <div style={{ opacity: assertDisplay("app") ? 1 : 0 }}>
              <Routes>
                {AllRoutes.map((ele) => (
                  <Route key={ele.path} element={<ele.component />} path={ele.path} />
                ))}
              </Routes>
            </div>
          </div>
        </main>
        <HideContent>
          {images.map((src) => (
            <img key={src} src={src} alt="" />
          ))}
          {flowState.successImage && <img src={flowState.successImage} alt="" />}
        </HideContent>
      </BVNContextProvider>
    </IDProvider>
  );
};

export default App;
