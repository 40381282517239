import { useEffect, useState } from "react";
import "~/packages/AWSLiveness.scss";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { VerificationAPI } from "@/utils/api";
import { LivenessError } from "@aws-amplify/ui-react-liveness/dist/types/components/FaceLivenessDetector/service";
import { toast } from "@ravenpay/raven-bank-ui";
import { log, logError } from "@/utils";
import { postMessage } from "@/utils/helper";

export interface AWSLivenessLibraryProps {
  onCapture(url: string): void;
  type: "bvn" | "nin";
  value: SNN;
  onBack(): void;
}

export function AWSLivenessLibrary(props: AWSLivenessLibraryProps) {
  const { onCapture, onBack } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [createLivenessApiData, setCreateLivenessApiData] = useState<string | null>(null);

  const handleGetSession = async () => {
    try {
      const res = await VerificationAPI.getSessionId();
      setCreateLivenessApiData(res.data.session_id);
      localStorage.setItem("session_id", res.data.session_id);
    } catch (error) {
      // console.log(error);
      // const obj = res.data;
      if (error === "invalid public key passed") {
        postMessage("failed_data", error);
        toast.error("Invalid session details");
        onBack();
        return;
      }
      postMessage("failed_data", error);
      toast.error("Unable to start session, please try again");
      onBack();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const time = 2 * 60 * 1000;

    const timer = setTimeout(() => {
      toast.error("Verification Process timed out due to inactivity please try again");
      onBack();
    }, time);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnalysisComplete: () => Promise<void> = async () => {
    try {
      const res = await VerificationAPI.getLivenessScore(createLivenessApiData ?? "");

      if (res.data.confidence_passed) {
        onCapture(res.data.reference_image_url);
        const obj = res.data;
        postMessage("success_data", obj);
      } else {
        postMessage("failed_data", res);
        toast.error("Liveness failed, please try again");
        onBack();
      }
    } catch (error) {
      toast.error(logError(error));
      onBack();
    }
  };

  const handleAllErrors = (error: LivenessError) => {
    if (error.state === "CAMERA_ACCESS_ERROR") {
      // console.log(error, "Camera");
      toast.error(`Camera permission needed to begin verification`);
      onBack();
      return;
    }

    toast.error(error.error.message);
    onBack();
  };

  return (
    <ThemeProvider>
      {loading && !createLivenessApiData ? (
        <div className="rvp-aws-liveness-loader">
          <img src="/raven_loader.gif" alt="" />
        </div>
      ) : (
        <FaceLivenessDetector
          sessionId={createLivenessApiData ?? ""}
          region="us-east-1"
          onAnalysisComplete={handleAnalysisComplete}
          onError={handleAllErrors}
          onUserCancel={onBack}
        />
      )}
    </ThemeProvider>
  );
}
