import { assertRef, log, sendIframeMessage } from "@/utils";
import { useEffect, useState } from "react";
import "~/steps/ConfirmImageCapture.scss";
import { useFlowContext } from "@/context/FlowContext";
import { useSearchParams } from "react-router-dom";
import { toast } from "@ravenpay/raven-bank-ui";
import UploadingIDStep from "./depreciated/Step6";
import { VerificationAPI } from "@/utils/api";
import { postMessage } from "@/utils/helper";

type State = BVNVerificationState | IDVerificationState;

type StateType = Extract<keyof BVNVerificationState | keyof IDVerificationState, VerificationMode>;

// const SPECIAL_ERROR_CASE = ["could not get nin data", "could not get bvn data"];

interface Props {
  /**
   * `bvn` | `nin`
   */
  type: StateType;
  state: State;
  onComplete(): void;
  onRetakeSelfie(): void;
  onFetchError(err: string): void;
}

const ConfirmImageCapture = (props: Props) => {
  const { onComplete, state, type, onFetchError } = props;

  const { flowState } = useFlowContext();
  const [loading, setLoading] = useState(false);

  const [query] = useSearchParams();

  const ref = query.get("reference");

  /**
   * some typescript gymnastic because state is a discriminated union as a result
   * keys for type do not exist in them and have to be narrowed
   * assertValue attempts to narrow them
   */
  const assertValue = () => {
    if (type in state) {
      const value = state[type as keyof typeof state];

      if (typeof value !== "string") {
        throw new Error(`Expected type string for ${type} but received ${value}`);
      }

      return value;
    }

    throw new Error(`${type} does not exist in state`);
  };

  const handleBVNDataUpload = async () => {
    setLoading(true);
    // const { file } = state;
    const { metaData } = flowState;

    // const fFile = dataUrlToFile(file, "user-image");

    const assertMetaData = (): Record<string, unknown> => {
      if (metaData?.verification.meta_data) {
        if (typeof metaData?.verification.meta_data === "string") {
          const metaObj = JSON.parse(metaData?.verification.meta_data);

          return { ...metaObj, reference: assertRef(ref) };
        }

        return { ...metaData?.verification.meta_data, reference: assertRef(ref) };
      }

      return { reference: assertRef(ref) };
    };

    try {
      const session_id = localStorage.getItem("session_id");

      // call back url value ------
      // bvn --- https://integrations.getravenbank.com/central/bvn_verification_webhook
      //   nin --- https://integrations.getravenbank.com/central/document_verification_webhook

      const payload = {
        [type]: assertValue(),
        meta_data: assertMetaData(),
        callback_url: metaData?.callback_url,
        session_id,
      };

      const res = await VerificationAPI.sendLivenessReport(payload, type);
      log(res);
      if (res.data.status === "success") {
        sendIframeMessage({ data: res.data.data, type: "onVerify" });
        const obj = res.data
        postMessage("success_data", obj)
      }
    } catch (error) {
      const err = String(error);
      log(error);
      toast.error(err);
      onFetchError(err);
      postMessage("failed_data", error)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleBVNDataUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UploadingIDStep isLoading={loading} onComplete={onComplete} />;
};

export default ConfirmImageCapture;
