import React from "react";
import "./style/NewFittedLayout.css";
import { WelcomeBVnNinIcon } from "@/assets/WElcomeIcon";
import { useFlowContext } from "@/context/FlowContext";
import { getQueryVariable } from "@/utils";

interface MyComponentProps {
  btnComponent?: React.ReactNode;
  bgContent?: React.ReactNode;
  textDesCOntent?: React.ReactNode;
}

const NewSuccessVerifiedLayout = ({
  bgContent,
  btnComponent,
  textDesCOntent,
}: MyComponentProps) => {
  const { flowState } = useFlowContext();
  const mode = getQueryVariable("origin");

  const getColorFunc = () => {
    let val;
    if (mode === "redpay") {
      val = "rgba(191, 0, 16, 1)";
    } else {
      if (flowState?.color === "purple-light") {
        val = "url(#paint0_linear_1135_23461)";
      }
      if (flowState?.color === "black-light") {
        val = "url(#paint0_linear_1135_23461)";
      }
      if (flowState?.color === "deep-green-light") {
        val = "rgba(1, 67, 69, 1)";
      }
    }

    return val;
  };

  return (
    <div
      //   onClick={() => {
      //     console.log(viewBox);
      //   }}
      className="new-layout-bvn-nin-wrapper-content-reuse new-layout-bvn-nin-wrapper-content-verified-reuse"
    >
      {/* bg image start  */}
      <div className="bg-img-box" style={{ backgroundColor: flowState?.iconBoxBg }}>
        <div className="img-wrap-box">
          <figure className="img-box">
            {WelcomeBVnNinIcon?.sucess(getColorFunc(), flowState?.iconBoxBg)}
          </figure>
        </div>
      </div>
      {/* bg image end */}
      {/* text description box start */}
      <div className="text-decription-btn-box">
        {/* text wrap box start */}
        <div className="text-wrap-box">{textDesCOntent}</div>
        {/* text wrap box end */}
        {/* btn start */}
        <div className="btn-wrap-box">{btnComponent}</div>
        {/* btn end */}
      </div>
      {/* text description box end */}
    </div>
  );
};

export default NewSuccessVerifiedLayout;
