// import Success from "@/assets/VST-Success.png";
import "~/steps/Step.Success.scss";
import Button from "@/component/fragments/Button";
// import {  getQueryVariable } from "@/utils";
// import { useFlowContext } from "@/context/FlowContext";
import { postMessage } from "@/utils/helper";
import NewSuccessVerifiedLayout from "./new-screen/NewSuccessVerifiedLayout";
import TitleTextComponent from "./new-screen/TitleTextComponent";

interface Props {
  onClick(): void;
  className?: string;
  type?: string;
}

const VerificationCompleted = ({ onClick, className, type }: Props) => {
  // const { flowState } = useFlowContext();

  // const isStandAlone = getQueryVariable("origin") === "personal";

  // const { successImage = Success } = flowState;

  return (
    <>
      <NewSuccessVerifiedLayout
        textDesCOntent={
          <>
            <TitleTextComponent
              title={`${type || ""} Verification submitted`}
              text={`Your ${
                type || ""
              } verification has been \n submitted successfully, our team will \n review it shortly`}
            />
          </>
        }
        btnComponent={
          <Button
            onClick={() => {
              const obj = {
                info: "This is for ending the session",
                status: "End session",
              };
              // console.log("skjjsks");
              
              postMessage("close_session", obj);
              onClick();
            }}
          >
            Done
          </Button>
        }
      />
    </>
    // <div className={cn("vst-success space-y-40", className)}>
    //   <div className="verification-header">
    //     <h2 style={{ marginTop: "3rem" }} className="verification-title">
    //       Your Identity is being verified
    //     </h2>
    //     <p>Please wait while we verify your identity , this might take a few mins</p>
    //   </div>
    //   <div className="vst-success__content">
    //     <figure>
    //       <img className="success-illustration" src={successImage ?? Success} alt="" />
    //     </figure>
    //   </div>
    //   <div className={cn(isStandAlone && "hide-for-mobile")}>
    //     <Button
    //       onClick={() => {
    //         const obj = {
    //           info: "This is for ending the session",
    //           status: "End session",
    //         };
    //         postMessage("close_session", obj);
    //         onClick();
    //       }}
    //     >
    //       Done
    //     </Button>
    //   </div>
    // </div>
  );
};

export default VerificationCompleted;
