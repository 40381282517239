import React, { useEffect, useRef, useState } from "react";
import "./style/NewFittedLayout.css";
import { WelcomeBVnNinIcon } from "@/assets/WElcomeIcon";
import { useFlowContext } from "@/context/FlowContext";
import { getQueryVariable } from "@/utils";

interface MyComponentProps {
  btnComponent?: React.ReactNode;
  bgContent?: React.ReactNode;
  textDesCOntent?: React.ReactNode;
}

const NewFittedLayout = ({bgContent, btnComponent, textDesCOntent}: MyComponentProps) => {
  const { flowState } = useFlowContext();
  const mode = getQueryVariable("origin");
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [viewBox, setViewBox] = useState("0 0 370 312");

  useEffect(() => {
    const updateViewBox = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setViewBox(`0 0 ${clientWidth} ${clientHeight}`);
      }
    };

    updateViewBox(); // Initial calculation
    window.addEventListener("resize", updateViewBox); // Recalculate on resize

    return () => window.removeEventListener("resize", updateViewBox);
  }, []);

  const getColorFunc = () => {
    let val;
    if (mode === "redpay") {
      val = "rgba(191, 0, 16, 1)";
    } else {
      if (flowState?.color === "purple-light") {
        val = "rgba(117, 90, 226, 1)";
      }
      if (flowState?.color === "black-light") {
        val = "rgba(27, 27, 27, 1)";
      }
      if (flowState?.color === "deep-green-light") {
        val = "rgba(1, 67, 69, 1)";
      }
    }

    return val;
  };

  return (
    <div
    //   onClick={() => {
    //     console.log(viewBox);
    //   }}
      className="new-layout-bvn-nin-wrapper-content-reuse"
    >
      {/* bg image start  */}
      <div className="bg-img-box">
        <div className="img-wrap-box">
          <figure ref={containerRef} className="img-box">
            {WelcomeBVnNinIcon?.icon(viewBox, getColorFunc())}
          </figure>
        </div>
      </div>
      {/* bg image end */}
      {/* text description box start */}
      <div className="text-decription-btn-box">
        {/* text wrap box start */}
        <div className="text-wrap-box">
            {textDesCOntent}
        </div>
        {/* text wrap box end */}
        {/* btn start */}
        <div className="btn-wrap-box">
            {btnComponent}
        </div>
        {/* btn end */}
      </div>
      {/* text description box end */}
    </div>
  );
};

export default NewFittedLayout;
